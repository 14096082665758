<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
    <!-- Table Container Card -->
    <b-card no-body>
    
      <b-card-body>
        
        <b-row>
          
              <b-col
                cols="12"
                md="4"
                class="mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem ">
            
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="sites"
                    label="site_name"
                    class="w-100"
                    placeholder="Project Site"
                    v-model="siteData"
                    :clearable="false"
                    
                  >
                    <!-- @input="filterTable" -->
                  <template #selected-option="{ site_name }">
                    <span v-if="site_name.length < 23">{{site_name}}</span>
                    <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
                  </template>
                  </v-select>
              </b-col>


              <b-col md="4" class="mobile_tab_max_width_flex mobile_tab_margin_bottom_point_five">
                <div class="d-flex align-items-center">
                      
                      <flat-pickr
                        v-model="date_range"
                        :config="date_config"
                        class="form-control flat-picker"
                        placeholder="Select Date Range"
                        @on-change="setDate()"
                      />

                    </div>
              </b-col>

              <b-col md="4" class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
                
                <b-button
                
                  variant="danger"
                  class="mobile-margin-bottom mr-1"
                  @click="resetFilter()"
                >
                  <span class="text-nowrap">Reset</span>
                </b-button>

                <!-- <b-button
                
                  variant="warning"
                  class="mobile-margin-bottom mr-1"
                  :to="{ name: 'add-inventory-order'}"
                >
                  <span class="text-nowrap">Add</span>
                </b-button> -->

              </b-col>

              

          

        </b-row>
          
      </b-card-body>
    </b-card>

    <b-card no-body class="mb-0" v-if="siteData != null">
      
      <!-- tab component starts -->

      <b-tabs pills justified class="margin_top_zero_mobile tab_ul button-padding table_button_tab">                
            
            <b-tab title="Draft" lazy @click="tab_status = 'draft'" :active="tab_status == 'draft'">
              <template #title>
                <div class="inline-button-items" style="padding-bottom: 3%;">
                  <span>Draft</span>
                </div>
              </template>
              
            </b-tab>
            
            <b-tab title="Pending" lazy @click="tab_status = 'pending'" :active="tab_status == 'pending'">
              <template #title>
                <div class="inline-button-items" style="padding-bottom: 3%;">
                  <span>Pending</span>
                  <b-badge variant="warning" style="margin-left: 5px;" v-if="pending > 0">
                      <span>{{ pending }}</span>
                  </b-badge>
                </div>
              </template>
              
            </b-tab>
            
            <b-tab title="Completed" lazy @click="tab_status = 'completed'" :active="tab_status == 'completed'">
              <template #title>
                <div class="inline-button-items" style="padding-bottom: 3%;">
                  <span>Completed</span>
                  
                </div>
              </template>
              
            </b-tab>
          

            

            <b-tab title="All" lazy @click="tab_status = 'all'" :active="tab_status == 'all'">
              <template #title>
                <div class="inline-button-items" style="padding-bottom: 3%;">
                  <span>All</span>
                  
                </div>
              </template>
              
            </b-tab>

            <ReportComponent :siteData="siteData" :start="start" :end="end" :tab_status="tab_status" @updateCount="updateCount"  :key="tab_status"/>

        </b-tabs>

      <!-- tab component ends -->

      
    </b-card>

    
    
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BAlert,
  BPagination,VBTooltip,BCardBody,BFormCheckbox,BFormDatepicker,BBreadcrumb, BTabs, BTab, BFormSelect,BFormSelectOption, BForm , BFormGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'
import moment from "moment";
import ReportComponent from './ReportComponent.vue';
import Bus from "../../../event-bus";
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BFormDatepicker,
    BBreadcrumb,
    BTabs, BTab,
    ReportComponent,
    flatPickr, BForm, BAlert,
    BFormSelect,BFormSelectOption , BFormGroup
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  
  data() {
    return {
      report_status: null,
      tab_status:'pending',
      // tab_status:'draft',
      items:[],
      isSortDirDesc: true,
      perPageOptions: [10, 20, 50, 100],
      perPage : 10,
      sortBy : 'order',
      webUrl: process.env.VUE_APP_SERVER_URL,
      totalRecords : 0,
      currentPage : 1,
      
      sites:[],
      siteData:null,

      locations: [],
			allLocations: [],
      
      // calendar vars 
      
      pending:0,
      
      // start: moment(new Date()).tz('Asia/Singapore').startOf('month').format('DD MMM YYYY'),
      // end: moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY'),

      start:'',
      end:'',

      date_config: {
        mode: 'range',
        dateFormat: 'd M Y',
        defaultDate: [
          '',''
          // moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY'),
          // moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY')
        ]
      },

      date_range: '',
      // date_range: moment(new Date()).tz('Asia/Singapore').startOf('month').format('DD MMM YYYY') + ' to ' + moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY'),

      // qm validation settings
      error_message:null,
      showDismissibleAlert:false,
    }
  },
  methods : {
    updateStatus(value){
			this.tab_status = value;
		},

    resetFilter(){

      this.allSites() 

      // this.start = moment(new Date()).tz('Asia/Singapore').startOf('month').format('DD MMM YYYY'),
      // this.end = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY'),
      // this.date_range = moment(new Date()).tz('Asia/Singapore').startOf('month').format('DD MMM YYYY') + ' to ' + moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY'); 
      this.start = '',
      this.end = '',
      this.date_range = ''; 
      
    },
    setDate(){
        var date = this.date_range.split(' to ');

        if (date[1]) {
            this.start = moment(new Date(date[0])).format('YYYY-MM-DD');
            this.end = moment(new Date(date[1])).format('YYYY-MM-DD');
        }else{
            this.start = moment(new Date(date[0])).format('YYYY-MM-DD');
            this.end = moment(new Date(date[0])).format('YYYY-MM-DD');
        }
		},

    updateCount(value){
			this.pending 			= value;
		},

    updateStatus(value){
			this.tab_status = value;
		},
    allSites(){
      return this.$store.dispatch(POST_API, {
         data:{
           role:this.$store.getters.currentUser.role,
           //om_sites:this.$store.getters.currentUser.om_sites,
         },
         api: '/api/all-sites'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.sites = this.$store.getters.getResults.data;

              var obj = {
                _id:'all-site',
                site_name:'All Project Sites',
                pdf_date_format: 'DD MMM YYYY',
                pdf_time_format: 'HH:mm'
              }
              this.sites.unshift(obj);
              
              // this.siteData = this.defaultSiteWithAllSite(this.sites);
              this.siteData = this.defaultSite(this.sites);
              
              return this.sites;
          }
      });
    },
    
    // calendar functions
    updateStart(){
      this.end = moment(this.start).tz('Asia/Singapore').toDate();
      
    },

    disabledDates(){
      if (this.start) {
        return moment(this.start).format('YYYY-MM-DD');
      }else{
        return moment().format('YYYY-MM-DD');
      }
    },

    breadCrumb(){
      var item = [
      {
          to: { name: 'client-dashboard' },
          text: 'Dashboard',
        }, {
          to: null,
          text: 'Inventory'
        }, {
          to: null,
          text: 'Overview',
          active: true
        }];
      return item;
    },


    
    
    
  },
  mounted() {
    this.allSites();
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
